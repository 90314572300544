import { computed, reactive, Ref } from 'vue';

import { getFormattedCurrency } from '@/helpers/number';
import { getNextPackagePrice, getPackagePrice } from '@/pages/payment/helpers/package/package';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { useNewYearSplit } from '@/services/growth-book/hooks/use-new-year-split/use-new-year-split';
import { useSplit } from '@/services/growth-book/hooks/use-split/use-split';

type UseSelfArg = {
  packageSelected: Ref<PaymentStatePackagePremium>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const splitNewYear = useNewYearSplit();
  const splitValentinesDay = useSplit('valentines-day');
  // Computed ------------------------------------------------------------------
  const packageSelectedPriceNextFormatted = computed(() => {
    if (arg.packageSelected.value!) {
      const packageNextPrice = getNextPackagePrice(arg.packageSelected.value!);
      return getFormattedCurrency(
        packageNextPrice?.totalAmount ?? 0,
        packageNextPrice?.currency ?? 'USD',
      );
    }
    return '';
  });

  const packageSelectedPriceFormatted = computed(() => {
    const packagePrice = getPackagePrice(arg.packageSelected.value!);
    return getFormattedCurrency(packagePrice?.totalAmount ?? 0, packagePrice?.currency ?? 'USD');
  });
  // Method --------------------------------------------------------------------

  function init() {
    splitNewYear.init();
    splitValentinesDay.init();
  }

  return reactive({
    init,
    packageSelectedPriceNextFormatted,
    packageSelectedPriceFormatted,
    splitNewYear,
    splitValentinesDay,
  });
}
