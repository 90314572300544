import { computed, reactive } from 'vue';

import { usePromoTimer } from '@/hooks/use-promo-timer/use-promo-timer';
import { useNewYearSplit } from '@/services/growth-book/hooks/use-new-year-split/use-new-year-split';
import { useSplit } from '@/services/growth-book/hooks/use-split/use-split';
import { useSplitClearPaywall } from '@/services/growth-book/hooks/use-split-clear-paywall/use-split-clear-paywall';
import { getState } from '@/store/store-helper';

export function useSelf() {
  // Ref -----------------------------------------------------------------------
  const { isOrganic } = getState().common.index;

  const TIMER_DURATION_SECONDS = 600;
  const TIMER_DURATION_RESTART_SECONDS = -1200;
  // Hook ----------------------------------------------------------------------
  const splitNewYear = useNewYearSplit();
  const splitClearPaywall = useSplitClearPaywall();
  const splitValentinesDay = useSplit('valentines-day');

  const promoTimer = usePromoTimer({
    durationSec: TIMER_DURATION_SECONDS,
    durationRestartSec: TIMER_DURATION_RESTART_SECONDS,
    timerName: 'ntf',
  });
  // Computed ------------------------------------------------------------------
  const timerMargin = computed(() => {
    if (splitNewYear.isV2) {
      return 'my-3';
    } else if (splitValentinesDay.variant === 'v3' || splitValentinesDay.variant === 'v4') {
      return 'mb-4';
    } else {
      return 'mb-4 mt-6';
    }
  });
  // Method --------------------------------------------------------------------

  function init() {
    splitNewYear.init();
    splitClearPaywall.init();
    splitValentinesDay.init();
    promoTimer.init();
  }

  function destroy() {
    promoTimer.destroy();
  }

  return reactive({
    init,
    timerMargin,
    splitNewYear,
    splitClearPaywall,
    splitValentinesDay,
    isOrganic,
    promoTimer,
    destroy,
  });
}
