import { isTiktokUrl } from '@project-gd-x/shared-web/src/helpers/landing-url/landing-url';

type TrafficSource = 'facebook' | 'taboola' | 'tiktok';

type ResolveTrafficSourceCb = {
  [key in TrafficSource]?: () => void;
};

type ResolveTrafficSourceOptions = {
  landingUrl: string;
};

export function resolveTrafficSource(
  { facebook, taboola, tiktok }: ResolveTrafficSourceCb,
  options: ResolveTrafficSourceOptions,
) {
  if (isTiktokUrl(options.landingUrl)) {
    tiktok?.();
  } else if (isTaboolaUrl(options.landingUrl)) {
    taboola?.();
  } else {
    facebook?.();
  }
}

export function isTaboolaUrl(url: string) {
  try {
    const { searchParams } = new URL(url);
    return searchParams.has('tbclid') || searchParams.get('utm_source') === 'tb';
  } catch {
    return false;
  }
}
