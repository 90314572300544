<template>
  <div
    class="fixed max-w-screen-xs mx-auto left-0 right-0 text-center h-14 flex items-center justify-center z-10"
    :class="self.splitValentinesDay.variant === 'v3' ? 'bg-promo' : 'bg-premium'"
  >
    <a
      href="#"
      data-test-id="btn_close"
      class="text-white absolute left-6 opacity-50"
      @click.prevent="emit('close')"
      ><i class="icon icon-close"></i
    ></a>
    <span class="text-tag text-white opacity-50">{{ t('activate') }}</span>
  </div>

  <div
    class="px-6"
    :class="{ 'bg-new-year bg-cover bg-bottom': self.splitNewYear.isV2 }"
    data-test-id="slider-ntf-purchase"
  >
    <PremiumSlider :feature="feature" />
  </div>

  <div class="bg-white flex-grow px-6" :class="{ 'rounded-t-2xl': !self.splitNewYear.isV2 }">
    <img
      v-if="self.splitNewYear.isV2"
      src="./assets/img/cristmas_sale.png"
      width="484"
      height="276"
      alt=""
      class="w-full max-w-[242px] mx-auto flex"
    />

    <div v-if="self.splitValentinesDay.variant === 'v3'">
      <DotLottieVue
        class="w-full h-full max-w-[238px] mx-auto mt-4"
        autoplay
        :speed="1"
        src="/meetmyage/happy_valentines_day.lottie"
      />
    </div>
    <div v-if="self.splitValentinesDay.variant === 'v4'" class="relative overflow-hidden mx-auto">
      <img
        src="./assets/img/valentines_day_sale.png"
        width="624"
        height="288"
        alt=""
        class="w-full h-full mx-auto flex relative z-10 pt-4 max-w-[312px]"
      />
      <DotLottieVue
        class="mx-auto w-[200%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        autoplay
        loop
        :speed="1"
        src="/meetmyage/flying_hearts.lottie"
      />
    </div>

    <PremiumTimer />

    <!-- Premium items start -->
    <div v-if="packageSelected">
      <PremiumItem v-for="pkg in packageList" :key="pkg.id" v-model="packageSelected" :pkg="pkg" />
    </div>
    <!-- Premium items end -->

    <div class="text-body1 text-dark-200 text-center my-2">
      <img src="./assets/img/ic_security.svg?url" alt="" /> {{ t('paySafe') }}
    </div>
    <div class="text-center">
      <img class="w-[250px]" src="./assets/img/pay-safe.png" width="500" alt="" />
    </div>

    <div class="text-overline text-dark-200 font-medium text-center mt-2 mb-28">
      {{
        t('hint', {
          label: packageSelected?.label ?? '',
          packageSelectedPriceFormatted: self.packageSelectedPriceFormatted,
          packageSelectedPriceNextFormatted: self.packageSelectedPriceNextFormatted,
        })
      }}
      <a
        class="text-overline text-dark-200 font-medium underline"
        href="mailto:support@meetmyage.com"
        >support@meetmyage.com</a
      >.
      <a
        class="text-overline text-dark-200 font-medium underline"
        href="#"
        @click="textPageModal.showModal('membership')"
        >{{ t('policy') }}</a
      >.
    </div>
  </div>

  <div class="">
    <PurchaseContinue
      :is-autorefill-visible="false"
      :btn-class="`${
        self.splitValentinesDay.variant === 'v3' ? '!bg-promo' : '!bg-premium'
      } text-white`"
      root-class-name="fixed max-w-screen-xs w-full mx-auto left-1/2 bottom-0 -translate-x-1/2 h-[72px] z-10 bg-white px-6 pt-2 pb-4 border-t border-dark-500"
      @select="emit('select')"
      @next="emit('next')"
    >
      <template #continue> {{ t('getPremium') }} </template>
    </PurchaseContinue>
  </div>
</template>

<script lang="ts" setup>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue';
import { PropType, Ref } from 'vue';

import PremiumItem from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/components/premium-item/PremiumItem.vue';
import PremiumSlider from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/components/premium-slider/PremiumSlider.vue';
import { useSelf } from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/hooks/use-self/use-self';
import PremiumTimer from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-timer/PremiumTimer.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { useModel } from '@/hooks/use-model/use-model';
import PurchaseContinue from '@/pages/payment/components/layout/components/purchase-continue/PurchaseContinue.vue';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { getTextPageModal } from '@/pages/text-page/helpers/text-page/text-page';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  packageList: {
    type: Array as PropType<PaymentStatePackagePremium[]>,
    required: true,
  },
  packageSelected: {
    type: Object as PropType<PaymentStatePackagePremium>,
    required: true,
  },
});

const emit = defineEmits(['next', 'close', 'select', 'update:packageSelected']);

const textPageModal = getTextPageModal();

const { t } = useI18n({ messages });

const packageSelected: Ref<PaymentStatePackagePremium> = useModel(props, 'packageSelected', emit);

const self = useSelf({ packageSelected });

self.init();
</script>
