<template>
  <template
    v-if="
      self.splitClearPaywall.isV2 ||
      self.splitValentinesDay.variant === 'v2' ||
      self.splitValentinesDay.variant === 'v3' ||
      self.splitValentinesDay.variant === 'v4'
    "
  >
    <div
      :class="[
        {
          [`${
            self.splitValentinesDay.variant === 'v3' ? '!border-promo' : '!border-premium'
          } border-2 active-popular`]: self.isSelected,
        },
      ]"
      class="billing_card popular discount best_deal transition-all relative"
      data-test-id="premium-item"
      @click="self.toggleSelect"
    >
      <div
        v-show="pkg.isPopular"
        class="popular_label"
        :class="{ '!bg-promo': self.splitValentinesDay.variant === 'v3' }"
      >
        {{ t('popular') }}
      </div>

      <div
        class="billing_card-check rounded-full bg-premium"
        :class="{ 'bg-promo': self.splitValentinesDay.variant === 'v3' }"
      >
        <i class="icon icon-success"></i>
      </div>

      <div
        class="w-full flex items-center justify-between relative"
        :class="[self.isSelected ? 'py-2' : 'py-3', pkg.isPopular ? ' h-20' : 'h-16']"
      >
        <div class="billing_card-body ms-1 flex flex-col gap-1">
          <div class="billing_card-title">{{ pkg.label }}</div>

          <div class="flex items-center gap-1">
            <div
              v-if="self.packagePricePerWeek.price.totalAmount"
              class="billing_card-price line-through text-dark-100"
            >
              {{ self.packagePricePerMonthOld }}
            </div>
            <FontIcon class="icon-arrow_right text-dark-100 !text-[16px]" />
            <div class="billing_card-price-green">
              {{ self.packagePricePerMonthNew }}
            </div>
          </div>
        </div>

        <div class="flex mt-auto">
          <div class="triangle_label bg-green w-24">
            <div class="triangle"></div>
            <div class="triangle_label-body">
              <div class="flex ms-2 font-semibold">
                <span class="text-caption">{{
                  getCurrencySymbol(self.packagePricePerWeek.price.currency)
                }}</span>
                <span class="mt-[2px] text-headline6">{{ self.packagePricePerDayInt }}</span>
                <span class="text-caption">{{ self.packagePricePerDayCents }}</span>
                <span class="text-overline opacity-80 lowercase font-normal mt-3 -ml-[14px]">
                  per day
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="self.splitClearPaywall.isV3 || self.splitClearPaywall.isV4">
    <div
      :class="{
        '!border-premium border-2 active-popular': self.isSelected,
      }"
      class="billing_card popular discount best_deal transition-all relative flex flex-col p-0 clean_paywall"
      data-test-id="premium-item"
      @click="self.toggleSelect"
    >
      <div
        v-show="pkg.isPopular"
        class="w-full text-overline text-white bg-premium rounded-t-sm text-center transition-all duration-500 ease-in-out"
        :class="self.isSelected ? 'h-[22px] py-1' : 'h-0 py-0'"
      >
        {{ t('popular') }}
      </div>

      <div
        class="w-full flex items-center justify-between relative h-16 px-4"
        :class="[self.isSelected ? 'py-2' : 'py-3']"
      >
        <div class="billing_card-check rounded-full bg-premium">
          <i class="icon icon-success"></i>
        </div>

        <div class="billing_card-body ms-1 flex flex-col gap-1">
          <div class="billing_card-title">{{ pkg.label }}</div>
        </div>

        <div class="flex">
          <div
            class="relative right-4 flex text-caption font-medium text-error bg-secondary-500 px-0.5 rounded h-fit line-through"
          >
            {{ self.packagePricePerMonthOld }}
          </div>
          <div class="triangle_label bg-green w-24 split_clear_paywall justify-center">
            <div class="triangle"></div>
            <div class="triangle_label-body">
              <div class="flex ms-2 font-semibold">
                <span class="text-button2">{{
                  getCurrencySymbol(self.packagePricePerWeek.price.currency)
                }}</span>
                <span class="mt-[2px] text-headline5">{{ self.packagePricePerMonthInt }}</span>
                <span class="text-button2">{{ self.packagePricePerMonthCents }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div
      :class="{
        'popular h-20': pkg.isPopular,
        'active-popular !border-premium border-2': self.isSelected,
        'h-16': !pkg.isPopular,
      }"
      class="billing_card popular discount best_deal transition-all relative"
      data-test-id="premium-item"
      @click="self.toggleSelect"
    >
      <div class="billing_card-check rounded-full bg-premium">
        <i class="icon icon-success"></i>
      </div>

      <div v-show="pkg.isPopular" class="popular_label">
        {{ t('popular') }}
      </div>

      <div class="billing_card-body ms-1">
        <div class="billing_card-title">{{ pkg.label }}</div>

        <div
          v-if="self.packagePricePerWeek.price.totalAmount"
          class="billing_card-price-green text-caption text-dark-200 line-through"
        >
          {{ self.packagePricePerDayFormatted }}
        </div>
      </div>

      <div
        class="ml-auto triangle_label bg-green !absolute right-4 w-24"
        :class="pkg.isPopular ? 'top-[27px]' : '-translate-y-1/2 top-1/2'"
      >
        <div class="triangle"></div>
        <div class="triangle_label-body">
          <div class="flex ms-2 font-semibold">
            <span class="text-caption">{{
              getCurrencySymbol(self.packagePricePerWeek.price.currency)
            }}</span>
            <span class="text-headline6 mt-[2px]">{{ self.packagePricePerDayInt }}</span>
            <span class="text-caption">{{ self.packagePricePerDayCents }}</span>
            <span class="text-overline opacity-80 lowercase font-normal mt-3 -ml-[14px]"
              >per day</span
            >
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import { useSelf } from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-paywall/components/premium-item/hooks/use-self/use-self';
import { getCurrencySymbol } from '@/helpers/number';
import { useModel } from '@/hooks/use-model/use-model';
import { useI18n } from '@/services/i18n';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n({ messages });

const selectedModel = useModel<ExtractClassFields<PremiumPackage>>(props, 'modelValue', emit);

const self = useSelf({ selectedModel, pkg: props.pkg });

self.init();
</script>
