import { computed, reactive, ref } from 'vue';

import { customEventTrigger, PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { getChainId, trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { useSplit } from '@/services/growth-book/hooks/use-split/use-split';
import { useTiBoSplit } from '@/services/growth-book/hooks/use-ti-bo-split/use-ti-bo-split';
import { dispatch } from '@/store/store-helper';

type UseSelfArg = {
  source: PromoShowPurchaseSource;
  handleNext: (packageSelected: PaymentStatePackagePremium) => void;
  handleClose: () => void;
  handleSelect: (packageSelected: PaymentStatePackagePremium) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const packageList = ref<PaymentStatePackagePremium[]>([]);
  const packageSelected = ref<PaymentStatePackagePremium>(packageList.value[0]);
  const page = ref('paywall');
  const pageIndex = ref(0);

  const splitTiBo = useTiBoSplit();
  const splitValentinesDay = useSplit('valentines-day');

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  const pageList = computed(() => {
    if (splitTiBo.variant === 'v2' || splitTiBo.variant === 'v3') {
      return ['paywall', 'checkout'];
    } else {
      return ['paywall'];
    }
  });

  const isPageLast = computed(() => pageIndex.value >= pageList.value.length - 1);

  // Method --------------------------------------------------------------------
  async function fetchPackageList() {
    const response = await dispatch('actionCommonGetAvailablePromos');
    packageList.value = response.packages;
  }

  function nextPage(): void {
    if (!isPageLast.value) {
      pageIndex.value += 1;
      setPage(pageList.value[pageIndex.value]);
    } else {
      arg.handleNext(packageSelected.value);
    }
  }

  function setPage(pageArg: string): void {
    page.value = pageArg;
  }

  function back() {
    if (pageIndex.value > 0) {
      pageIndex.value -= 1;
      setPage(pageList.value[pageIndex.value]);
    } else {
      arg.handleClose();
    }
  }

  function select(path?: string) {
    if (!isPageLast.value) {
      const chainId = getChainId();

      customEventTrigger({
        event: 'click',
        name: 'click_promo',
        chainId,
      });

      pageIndex.value += 1;
      setPage(pageList.value[pageIndex.value]);
    } else {
      customEventTrigger({
        event: 'page_show',
        name: 'payment_flow',
        step: 'add',
        click_source: path || 'continue',
      });

      arg.handleSelect(packageSelected.value);
    }
  }

  async function init() {
    await fetchPackageList();
    await splitTiBo.init();
    await splitValentinesDay.init();

    if (packageList.value.length > 0) {
      packageSelected.value = packageList.value[0];
    }

    trackPaymentPromo({
      from: arg.source,
      popup: 'premium-ntf-v2',
      type: 'premium',
    });
  }

  return reactive({
    packageList,
    packageSelected,
    splitValentinesDay,
    init,
    setPage,
    page,
    nextPage,
    back,
    select,
  });
}
