import { reactive, ref } from 'vue';

import { getGrowthBookFeatureValue, GrowthBookFeatures } from '@/services/growth-book/growth-book';

// const fetchMyProfileFull = memoize(() => dispatch('actionMyProfileGetFull'));

export function useSplit<T extends keyof GrowthBookFeatures>(featureName: T) {
  const variant = ref<GrowthBookFeatures[T]>('def');
  const isInited = ref(false);

  async function init() {
    try {
      // const profileFull = getState().myProfile.full ?? (await fetchMyProfileFull());
      // const value = await getGrowthBookFeatureValue(featureName, 'def', {
      //   regDateUnix: profileFull.timeCreated,
      // });

      variant.value = (await getGrowthBookFeatureValue(
        featureName,
        'def',
      )) as GrowthBookFeatures[T];
    } finally {
      isInited.value = true;
    }
  }

  return reactive({
    variant,
    isInited,
    init,
  });
}
